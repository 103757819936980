<template>
  <div>
    <b-card title="Filter">
      <b-row>
        <b-col
          md="5"
          class="mb-md-0 mb-2"
        >
          <label>Merchant</label>
          <v-select
            v-model="merchantNo"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="merchants"
            :clearable="false"
            :searchable="true"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          md="5"
          class="mb-md-0 mb-2"
        >
          <label>Date Range</label>
          <date-picker
            v-model="dateRange"
            value-type="format"
            format="YYYY-MM-DD"
            range
            confirm
            :clearable="false"
          />
        </b-col>

        <b-col
          md="2"
          class="mb-md-0 mb-2"
        >
          <b-button
            class="mt-2"
            variant="primary"
            @click="doSearch"
          >
            Search
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card no-body>
      <b-table
        class="position-relative"
        responsive
        :fields="tableFields"
        :items="records"
        :per-page="perPage"
        :current-page="currentPage"
        primary-key="settlement_id"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
      >

        <template #cell(merchant)="data">
          {{ data.item.merchant_name }}
          <small class="text-muted">@{{ data.item.merchant_no }}</small>
        </template>

        <template #cell(period)="data">
          {{ data.item.start_date }} ~ {{ data.item.end_date }}
        </template>

        <template #cell(total)="data">
          {{ data.item.apply_currency }} {{ data.item.apply_amount | numberFilter }}
        </template>

        <template #cell(settleable)="data">
          {{ data.item.payout_currency }} {{ data.item.payout_amount | numberFilter }}
        </template>

        <template #cell(status)="data">
          {{ formatStatus(data.item.payout_status) }}
        </template>

        <template #cell(payout_date)="data">
          {{ data.item.payout_date === '' ? 'N/A' : data.item.payout_date }}
        </template>

        <template #cell(sales_statement_report_id)="data">
          <b-link
            :to="{ name: 'salesstatement-detail',
                   params: { id: data.item.settlement_id },
                   query: {
                     sales_statement_report_id: data.item.sales_statement_report_id,
                     merchant_name: data.item.merchant_name,
                     start_date: data.item.start_date,
                     end_date: data.item.end_date,
                     create_time: data.item.create_time,
                     create_by: data.item.create_by,
                   }
            }"
            target="_blank"
          >
            {{ data.item.sales_statement_report_id }}
          </b-link>
        </template>

        <template #cell(settlement_report_id)="data">
          <b-link
            :to="{ name: 'settlement-detail',
                   params: { id: data.item.settlement_id },
                   query: {
                     settlement_report_id: data.item.settlement_report_id,
                     sales_statement_report_id: data.item.sales_statement_report_id,
                     merchant_name: data.item.merchant_name,
                     start_date: data.item.start_date,
                     end_date: data.item.end_date,
                     create_time: data.item.create_time,
                     create_by: data.item.create_by,
                   }
            }"
            target="_blank"
          >
            {{ data.item.settlement_report_id }}
          </b-link>
        </template>

        <template #cell(sales_statement_report_url)="data">
          <b-link
            @click.stop="downloadReport(data.item.sales_statement_report_url)"
          >
            {{ data.item.sales_statement_report_url }}
          </b-link>
        </template>

        <template #cell(settlement_report_url)="data">
          <b-link
            @click.stop="downloadReport(data.item.settlement_report_url)"
          >
            {{ data.item.settlement_report_url }}
          </b-link>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton, BCard, BRow, BCol, BTable, BLink, BPagination,
} from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import vSelect from 'vue-select'
import moment from 'moment-timezone'
import axios from '@axios'
import { showToast } from '@/libs/tool'
import useSettlementJs from './settlement'

const {
  fetchMerchantOptions,
  fetchSettlementReports,
  querySalesStatementReportDetail,
  querySettlementReportDetail,
} = useSettlementJs()

export default {
  components: {
    BButton,
    BCard,
    BRow,
    BCol,
    BTable,
    BLink,
    BPagination,
    vSelect,
    DatePicker,
  },
  filters: {
    numberFilter(value) {
      let realVal = ''
      if (value || value === 0) {
        realVal = parseFloat(value).toFixed(2)
      } else {
        realVal = '--'
      }
      return realVal
    },
  },
  data() {
    return {
      merchantNo: '',
      merchants: [],
      dateRange: [],
      searchKey: '',
      tableFields: [
        { key: 'settlement_id', label: 'ID' },
        { key: 'create_time', label: 'CREATE TIME' },
        'merchant',
        { key: 'period', label: 'REPORT PERIOD' },
        { key: 'total', label: 'TOTAL AMOUNT' },
        { key: 'settleable', label: 'SETTLEABLE AMOUNT' },
        'status',
        { key: 'payout_date', label: 'SETTLEMENT DATE' },
        { key: 'sales_statement_report_id', label: 'SALES STATEMENT ID' },
        { key: 'sales_statement_report_url', label: 'SALES STATEMENT REPORT' },
        { key: 'settlement_report_id', label: 'SETTLEMENT ID' },
        { key: 'settlement_report_url', label: 'SETTLEMENT REPORT' },
      ],
      records: [],
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      perPage: 10,
      currentPage: 1,
      totalRecords: 0,
    }
  },
  watch: {
    currentPage() {
      this.recalMetaData()
    },
  },
  created() {
    this.dateRange = [moment().startOf('month').startOf('day').format('YYYY-MM-DD 00:00:00'), moment().format('YYYY-MM-DD 23:59:59')]
    fetchMerchantOptions(
      merchants => {
        this.merchants = merchants
        this.merchantNo = this.merchants[0].value
        // this.merchantNo = ''
      },
      fail => {
        showToast(this, 'Warning', `Fetch merchant optinos with ${fail}`)
      },
    )
  },
  methods: {
    recalMetaData() {
      this.dataMeta.from = this.currentPage * 10 - (10 - 1)
      this.dataMeta.to = this.totalRecords - this.currentPage * 10 > 0 ? this.currentPage * 10 : this.totalRecords
      this.dataMeta.of = this.totalRecords
    },
    formatStatus(status) {
      switch (status) {
        case 0:
          return 'Processing'
        case 1:
          return 'Settled'
        default:
          return 'Unknown'
      }
    },
    doSearch() {
      this.records = []
      const [start, end] = this.dateRange
      const params = {
        merchant_no: this.merchantNo,
        start_date: start,
        end_date: end,
      }
      fetchSettlementReports(
        params,
        records => {
          this.records = records

          this.totalRecords = records.length
          this.recalMetaData()
        },
        fail => {
          showToast(this, 'Warning', `Fetch records with ${fail}`)
        },
      )
    },
    querySalesStatementReportDetail(settlementId) {
      querySalesStatementReportDetail(
        settlementId,
        () => {
          this.$refs.salesStatementDetailModal.show()
        },
        fail => {
          showToast(this, 'Warning', `Query SalesStatement Report Detail Failed with ${fail}`)
        },
      )
      // axios.post("/settlement/sales-statement-report/detail?settlement_id=" + tr.settlement_id)
      //   .then((response) => {
      //     const detail = {
      //       base_info: tr,
      //       detail: response.data
      //     }
      //     this.salesStatementDetail = detail
      //     this.salesStatementPopupActive = true
      //   })
    },
    querySettlementReportDetail(settlementId) {
      querySettlementReportDetail(
        settlementId,
        () => {
        },
        fail => {
          showToast(this, 'Warning', `Query Settlement Report Detail Failed with ${fail}`)
        },
      )
    },
    downloadReport(reportId) {
      axios.post(`/settlement/download?key=${reportId}`, null, { responseType: 'blob' })
        .then(response => {
          const { headers } = response
          const contentType = headers['content-type']

          if (response.data) {
            const blob = new Blob([response.data], {
              type: contentType,
            })

            const contentDisposition = headers['content-disposition']
            let fileName = 'unknown'
            if (contentDisposition) {
              fileName = window.decodeURI(headers['content-disposition'].split('=')[1])
            }
            this.downFile(blob, fileName)
          }
        })
    },
    downFile(blob, fileName) {
      if ('download' in document.createElement('a')) {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(link.href)
        document.body.removeChild(link)
      } else {
        window.navigator.msSaveBlob(blob, fileName)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.mx-datepicker-range {
  width: 100% !important;
}
.mx-input {
  height: 37px;
}
</style>
